import React from 'react'

import { ThemeProvider } from '@leshen/ui'
import siteTheme from '../../theme'
import 'typeface-ubuntu'

const Wrapper = ({ children }) => {
  return <ThemeProvider theme={siteTheme}>{children}</ThemeProvider>
}

export default Wrapper
